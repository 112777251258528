import React from 'react'
import { GatsbyImage } from 'gatsby-plugin-image'
import { Link } from 'gatsby'


import * as styles from './horseSlide.module.css'

const HorseSlide = ({ image, heading, subHeading ,description, slug }) => (
    <div className={styles.horse_slide_container}>
            <li className={styles.horse_list} key={slug}>
              <Link to={`/paard/${slug}`} className={styles.link}>
                <GatsbyImage alt="" image={image} />
                <h2 className={styles.title}>{heading}</h2>
                <h3>{subHeading}</h3>
              </Link>
              <div>
                {description}
              </div>
            </li>
    </div>
)

export default HorseSlide