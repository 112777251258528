import React from 'react'
import Layout from '../components/layout'
import ImageTextBlock from '../components/imageTextBlock/imageTextBlockView'
import { graphql} from 'gatsby'
import { Swiper, SwiperSlide} from "swiper/react";
import 'swiper/css';

import "swiper/css/pagination";
import useWindowDimensions from '../hooks/useWindowDimensions'
import { Pagination, Navigation } from "swiper";
import HorseSlide from '../components/horseSlide/horseSlideView'





const ManegeIndex = (props) => {
    let slidesAmount;
    const {width} = useWindowDimensions();

    let textBlocks = props.data.allContentfulManegePage.nodes[0].textBlock;
    const horses = props.data.allContentfulManegePage.nodes[0].manegePaarden;
    if (width > 1200){
      slidesAmount = 3;
    }
    else{
      slidesAmount = 1;
    }

    const intro = textBlocks[0]

    
    


    return (
      <Layout title={props.data.allContentfulManegePage.nodes[0].title} location={props.location}>
        <div className='empty_box'></div>
        <h1 style={{textAlign: 'center'}}>{props.data.allContentfulManegePage.nodes[0].title}</h1>
        <div style={{display: 'flex', flexWrap: 'wrap', maxWidth: 'var(--size-max-width)', justifyContent: 'space-between', margin: '0 auto' }}>
          <ImageTextBlock heading={intro.heading} subHeading="" image={intro.image?.gatsbyImageData} content={intro.text} small={!intro.largeBlock} />
        </div>

        <h2 style={{textAlign: 'center'}}>Manegepaarden</h2>
        <div style={{display: 'flex', justifyContent: 'center'}}>
          <div className='swiper_container' style={{marginBottom: '20px'}} >
            <Swiper
            slidesPerView={slidesAmount}
            spaceBetween={30}
            loop={true}
            pagination={{
              clickable: true,
            }}
            navigation={true}
            modules={[Pagination, Navigation]}
            className="mySwiper"
            >
              {(horses).map((horse) => <SwiperSlide key={horse.heading}><HorseSlide  heading={horse.heading} subHeading={horse.subheading} description={horse.shortDescription}  image={horse.image?.gatsbyImageData} slug={horse.slug} ></HorseSlide></SwiperSlide>) }
            </Swiper>
          </div>
        </div>
        <div style={{display: 'flex', flexWrap: 'wrap', maxWidth: 'var(--size-max-width)', justifyContent: 'space-between', margin: '0 auto' }}>
        {textBlocks.slice(1).map((block) => <ImageTextBlock key={block.heading} heading={block.heading} subHeading="" image={block.image?.gatsbyImageData} content={block.text} small={!block.largeBlock} />) }
        </div>
      </Layout>

    )
  }


export default ManegeIndex

export const PageQuery = graphql`
query ManegeQuery {
  allContentfulManegePage {
    nodes {
      title
      manegePaarden {
        slug
        heading
        subheading
        shortDescription
        image {
          gatsbyImageData(width: 300, height: 300)
        }
      }
      textBlock {
        image {
          gatsbyImageData
        }
        heading
        largeBlock
        text {
          raw
        }
      }
    }
  }
}
`




